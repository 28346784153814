var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": false, round: "" },
          model: {
            value: _vm.popShow,
            callback: function($$v) {
              _vm.popShow = $$v
            },
            expression: "popShow"
          }
        },
        [
          _c("div", { staticClass: "popup" }, [
            _vm.isRefund
              ? _c(
                  "div",
                  { staticClass: "refund" },
                  [
                    _c("h2", { staticStyle: { fontSize: "16px" } }, [
                      _vm._v("模式: "),
                      _c("span", { staticStyle: { color: "#FF2D2E" } }, [
                        _vm._v("退款")
                      ])
                    ]),
                    _c("van-field", {
                      attrs: {
                        type: "number",
                        label: "金额",
                        border: false,
                        placeholder: "请输入金额"
                      },
                      model: {
                        value: _vm.refundNb,
                        callback: function($$v) {
                          _vm.refundNb = $$v
                        },
                        expression: "refundNb"
                      }
                    }),
                    _c("h3", { staticStyle: { fontSize: "16px" } }, [
                      _vm._v("指定收款账户 ")
                    ]),
                    _c("div", { staticStyle: { marginTop: "8px" } }, [
                      _c("p", { staticStyle: { fontSize: "14px" } }, [
                        _vm._v(
                          "收款人：" +
                            _vm._s(
                              _vm.account.name ? _vm.account.name : "暂无"
                            ) +
                            " "
                        )
                      ]),
                      _c("p", { staticStyle: { fontSize: "14px" } }, [
                        _vm._v(
                          "收款账号：" +
                            _vm._s(_vm.account.num ? _vm.account.num : "暂无") +
                            "(" +
                            _vm._s(
                              _vm.account.bank ? _vm.account.bank : "暂无"
                            ) +
                            ") "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          staticStyle: { background: "#d1d1d1" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn", on: { click: _vm.submit } },
                        [_vm._v("提交")]
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "DEPOSIT" },
                  [
                    _c("h2", { staticStyle: { fontSize: "16px" } }, [
                      _vm._v("模式: "),
                      _c("span", { staticStyle: { color: "#FF2D2E" } }, [
                        _vm._v("充值")
                      ])
                    ]),
                    _c("van-field", {
                      attrs: {
                        type: "digit",
                        label: "金额:",
                        placeholder: "仅支持整千金额"
                      },
                      model: {
                        value: _vm.edpositNb,
                        callback: function($$v) {
                          _vm.edpositNb = $$v
                        },
                        expression: "edpositNb"
                      }
                    }),
                    _c("h4", { staticStyle: { fontSize: "16px" } }, [
                      _vm._v("指定收款账户 ")
                    ]),
                    _c("p", { staticStyle: { fontSize: "14px" } }, [
                      _vm._v("收款人：" + _vm._s(_vm.Banklist.name))
                    ]),
                    _c(
                      "p",
                      { staticStyle: { fontSize: "14px" } },
                      [
                        _vm._v(
                          "收款账号：" +
                            _vm._s(_vm.Banklist.num) +
                            "(" +
                            _vm._s(_vm.Banklist.bank) +
                            ") "
                        ),
                        _c("van-icon", {
                          staticStyle: { color: "'#fff'" },
                          attrs: { name: "column" },
                          on: {
                            click: function($event) {
                              return _vm.copy(_vm.Banklist.num)
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("h4", { staticStyle: { fontSize: "14px" } }, [
                      _vm._v("上传转账截图：")
                    ]),
                    _c(
                      "van-uploader",
                      {
                        attrs: {
                          "after-read": _vm.afterRead,
                          multiple: "",
                          "max-count": 3,
                          "preview-size": "90"
                        },
                        model: {
                          value: _vm.fileList,
                          callback: function($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList"
                        }
                      },
                      [
                        _c("div", { staticClass: "file" }, [
                          _c("div", { staticClass: "one" }),
                          _c("div", { staticClass: "two" })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn",
                          staticStyle: { background: "#d1d1d1" },
                          on: { click: _vm.close }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "div",
                        { staticClass: "btn", on: { click: _vm.depSbt } },
                        [_vm._v("确认")]
                      )
                    ])
                  ],
                  1
                )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }