<template>
  <div>
      <van-popup v-model="popShow" :close-on-click-overlay=false round >
        <div class="popup">
              <!-- 退押金 -->
          <div class="refund" v-if="isRefund">
              <h2 style="fontSize:16px">模式: <span style="color:#FF2D2E">退款</span></h2>
              <van-field v-model="refundNb" type="number" label="金额" :border="false"  placeholder="请输入金额"/>
                <h3 style="fontSize:16px">指定收款账户 </h3>
                <div style="marginTop:8px">
                <p  style="fontSize:14px">收款人：{{account.name?account.name:'暂无'}} </p>
                <p  style="fontSize:14px">收款账号：{{account.num?account.num:'暂无'}}({{account.bank?account.bank:'暂无'}})
               <!-- <van-icon name="column" style="color:'#fff'" @click="copy(account.num || '')"/> -->
                </p>
                </div>
         <div class="flex">
                    <div class="btn" @click="close" style="background:#d1d1d1">取消</div>
                    <div class="btn" @click="submit">提交</div>
                </div>

          </div>

          <!-- 交押金 -->
          <div class="DEPOSIT" v-else>
              <h2 style="fontSize:16px">模式: <span style="color:#FF2D2E">充值</span> </h2>
              <van-field v-model="edpositNb" type="digit" label="金额:"  placeholder="仅支持整千金额"/>
              <h4 style="fontSize:16px">指定收款账户 </h4>
                <p style="fontSize:14px">收款人：{{Banklist.name}}</p>
                <p style="fontSize:14px">收款账号：{{Banklist.num}}({{Banklist.bank}})
               <van-icon name="column" style="color:'#fff'" @click="copy(Banklist.num)"/>
                </p>
              <h4 style="fontSize:14px">上传转账截图：</h4>
              <van-uploader v-model="fileList" :after-read="afterRead"  multiple :max-count="3" preview-size="90">
                        <div class="file">
                            <div class="one"></div>
                            <div class="two"></div>
                        </div>
              </van-uploader>
                <div class="flex">
                    <div class="btn" @click="close" style="background:#d1d1d1">取消</div>
                    <div class="btn" @click="depSbt">确认</div>
                </div>
          </div>
        </div>
      </van-popup>
  </div>
</template>

<script>
import * as api from'@/utils/api'
import axios from 'axios'
export default {
    props:['popShow','isRefund','userInfo','depositlist'],
    inject: ["reload"],
    data() {
        return {
            // 退押金金额数
            refundNb:'',
            // 交押金金额数
            edpositNb:'',
            // 用户账户信息
            account:{},
            // 系统账户信息
            Banklist:{},
            // 已上传的文件列表
             fileList: [],
            //  上传图片至服务器后返回的key值
             fileKey:[],
            fileBaseUrl:process.env.NODE_ENV == "dev"
                ? "/shenzhenyht"
                : "https://shenzhenyht.oss-cn-shenzhen.aliyuncs.com",
            localtoken:localStorage.getItem("token"),
          isClick: false, // 退押金防止二次点击
        }
    },
    methods:{
        // 文件上传完毕之后的回调,获取到对应的 file 对象
       async  afterRead(file){
        console.log('1',file);
             const that = this;
                const data = {
                       size: 1
                     };
            api.apiFile(data).then((res) => {
                console.log('res',res);
                this.fileKey.push(res[0].key);
                console.log('key',this.fileKey);
                console.log(this.fileList);
                that.uploadFile(file.content, res[0].url);
        });
        },
          // 第二步上传文件
      uploadFile(imgpath,url) {
    // debugger;
    // 去掉url域名 保留后面
    const urllast =
      "/" +
      url
        .split("/")
        .slice(3)
        .join("/");
    const configs = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + this.localtoken
      }
    };
    const transformRequest = [
      function(data) {
        // debugger;
        let ret = "";
        for (const it in data) {
          ret +=
            encodeURIComponent(it) + "=" + encodeURIComponent(data[it]) + "&";
        }
        return ret;
      }
    ];
    const imgarr = this.base64ToUint8Array(imgpath);
    axios({
      url: this.fileBaseUrl + urllast,
      method: "put",
      headers: {
        "Content-Type": "application/octet-stream",
        Authorization: "Bearer " + this.localtoken
      },
      data: imgarr
    }).then(res => {
      console.log("请求结果：", res);
    });

    // axios.put(this.fileBaseUrl+urllast,imgarr,{configs,transformRequest}).then(res=>{
    //   console.log(res)
    // })
    // certifi.updaFileapi(this.fileBaseUrl+urllast,imgpath,{}).then(res=>{
    //   console.log(res)
    // })
        },
      base64ToUint8Array(base64String) {
    // debugger;
    const base64clear = base64String.substring(base64String.indexOf(",") + 1);
    const padding = "=".repeat((4 - (base64clear.length % 4)) % 4);
    const base64 = (base64clear + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },
        // 交押金提交按钮事件
       async depSbt(){
            // 判断提交金额是否是1000的倍数
            if (this.edpositNb%1000 ==0 && this.edpositNb != 0) {
                // 是1000的倍数
                // 判断是否上传截图
                    if (this.fileList.length) {
                       let res = await api.returnDeposit({
                          type:'DEPOSIT', //参数为REFUND 是退押金类型
                          money:this.edpositNb, //退款金额
                          payedInfo: this.fileKey
                       })
                         this.edpositNb = '' //清空交押金金额数
                         this.fileList = [] //清空已上传的文件列表
                         this.fileKey = []  //清空文件key值
                         this.$emit('update:popShow' ,false)
                         this.reload()
                    }else {
                        // 没有上传
                       this.$toast('请上传转账截图证明')
                    }

            }else {
                this.$toast('请输入正确的金额')
            }
        },
        // 退押金提交按钮事件
       async submit(){
// "PROCESS"
         if (this.isclick) {
           setTimeout(() => {
             this.isclick = false;
           }, 1000)
           return false
         }
         this.isclick = true
            const shenHe = this.depositlist.filter((item)=>{
              return item.status == 'PROCESS'
            })
            let totleShenHe =  0
            shenHe.forEach((item)=>{
              totleShenHe += item.money
            })
            // 判断输入的金额是否大于可退押金金额
            if (!this.refundNb || this.refundNb == 0) {
                return this.$toast('请输入金额')
            }
            if (this.refundNb>(this.userInfo.freeEarnestMoney-totleShenHe)) {
                this.$toast('押金不足')
            }else{
              // 提交之后关闭弹出层
            this.$emit('update:popShow' ,false)
                let res = await api.returnDeposit({
                    type:'REFUND', //参数为REFUND 是退押金类型
                    money:this.refundNb //退款金额
                })

            this.reload()
            }
         this.isclick = false
        },
        // 复制功能
        copy(txt){
            this.$copyText(txt).then(()=>{
                this.$toast('卡号已复制到剪贴板')
            })
        },
        // 关闭按钮事件
        close(){
            this.edpositNb = '' //清空交押金金额数
            this.fileList = [] //清空已上传的文件列表
            this.fileKey = []  //清空文件key值
            this.refundNb = '' //清空退押金金额数
            this.$emit('update:popShow' ,false)
        },
        // 定义获取用户账户信息
        async getAuthUser(){
        let res= await api.getUserAccount()
        if (res.length == 0) {
            this.$toast('请添加银行卡')
            this.$router.push({
              name:"AddBank",
              query:{path:'pricingDeposit',type:'addSave'}
            })
            return
        } else{
        res.forEach(item => {
            if (item.def) {
               console.log('item',item);
                this.account = item
            }else{
                this.account = res[0]
            }
        });
        }




        console.log(res);
        },
        // 获取系统银行卡信息
        async getBanklist(){
        let res = await api.banklist()
        console.log('1',res);
        if (res.length == 0) {
                // 代表没有数据 则没有银行卡信息 需要去设置指定收款账户
            console.log('需跳转设置指定收款账户');
        }
        this.Banklist = res[0].bankCard
        }
    }
}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .van-cell{
        padding: 0;
        margin: 30px 0;
        .van-cell__title{
            font-size: 32px;
            width: 80px;
            color: #1a1a1a;
            padding-top: 8px;
        }
        .van-field__control{
            font-size: 32px;
            color: #1a1a1a;
        }
    }
   }
    .popup{
        width: 92vw;
        padding: 20px 20px;
    }
    .refund,.DEPOSIT{
        padding: 30px 0;
        h4{
            padding: 10px 0;
        }
        p{
            padding: 10px 0;
            color: #a09999;
        }

    .btn{
        width: 260px;
        background-color: #ff2d2e;
        height: 80px;
        border-radius: 120px;
        margin: 60px auto 0;
        text-align: center;
        color: #fff;
        line-height: 80px;
        font-size: 30px;
    }

    }
     .icon{
        position: absolute;
        top: 0px;
        right: 0;
        color: fff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 40px;
        text-align: center;
        line-height: 55px;
    }
    ::v-deep{
        .van-uploader__preview{
            margin: 0 !important;
            .van-image{
                    height: 100% !important;
                    width: 156px !important;
            }
            .van-uploader__preview-delete {
                        top: 0;
                        right: 0;
                        width: 40px;
                        height: 40px;
                        background-color: rgba(0,0,0,.7);
                        border-radius: 0 0 0 40px;
                        .van-icon  {
                        position: absolute;
                        top: -10px;
                        right: -14px;
                        color: #fff;
                        font-size: 40px;
                        -webkit-transform: scale(.5);
                        transform: scale(.5);
                    }
                    }

        }
    }
    .file{
        position: relative;
        width: 150px;
        height: 194px;
        border: 1px dashed #ccc;
        .one{
            width: 110px;
            height: 1px;
            background-color: #ccc;
            position: absolute;
            top: 50%;
            left: 20px;
        }
        .two{
            width: 1px;
            height: 110px;
            background-color: #ccc;
            position: absolute;
            top: 42px;
            left: 50%;
        }

    }
</style>
